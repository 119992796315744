export const useFormatTelephone = (phoneNumber: number, countryCode: string = 'BR'): string => {
	if (countryCode.toUpperCase() !== 'BR') {
		return `+${countryCode} ${phoneNumber}`
	}

	const phoneNumberString: string = phoneNumber.toString()
	const areaCode: string = phoneNumberString.slice(2, 4)
	const digits: string = phoneNumberString.slice(4)

	let formattedNumber: string = `(${areaCode})`

	if (digits.length === 8) {
		formattedNumber += ` ${digits.slice(0, 4)}-${digits.slice(4)}`
	} else if (digits.length === 9) {
		formattedNumber += ` ${digits.slice(0, 5)}-${digits.slice(5)}`
	} else {
		throw new Error('Invalid phone number')
	}

	return formattedNumber
}
