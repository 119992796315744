<template>
	<img id="slide" class="rounded-box w-full" :src="currentImage" :title="currentTitle" />
</template>

<script setup lang="ts">
const listImage = [
	['https://cdn.micaellemassagem.com.br/slide/1.jpg', 'Recepção com balcão de mármore, fonte e pintura de praia'],
	['https://cdn.micaellemassagem.com.br/slide/2.jpg', 'Casa branca com cerca de arame farpado'],
	['https://cdn.micaellemassagem.com.br/slide/3.jpg', 'Casa branca com cerca de arame farpado'],
	['https://cdn.micaellemassagem.com.br/slide/4.jpg', 'Uma mesa de massagem com duas velas acesas em uma sala escura'],
	['https://cdn.micaellemassagem.com.br/slide/5.jpg', 'Duas mesas de massagem em uma sala escura'],
	['https://cdn.micaellemassagem.com.br/slide/6.jpg', 'Mesa de massagem em uma sala escura com uma vela acesa']
]

let slideImageCurrent: number = 0

const slideImageRotator = (): void => {
	slideImageCurrent += 1

	if (slideImageCurrent > 6) {
		slideImageCurrent = 0
	}
}

const currentImage: string = listImage[slideImageCurrent][0]
const currentTitle: string = listImage[slideImageCurrent][1]

onMounted(() => {
	const slideImage = document.getElementById('slide') as HTMLImageElement

	setInterval(() => {
		slideImageRotator()

		slideImage.src = listImage[slideImageCurrent][0]
		slideImage.title = listImage[slideImageCurrent][1]
	}, 7500)
})
</script>
