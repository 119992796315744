<template>
	<span class="text-lg text-base-content">Nossas Massagens</span><br />
	<nuxt-img src="/image/index/massage.jpg" quality="75"
		class="rounded-box object-cover object-center w-full h-[225px]" /><br />
	<div class="grid grid-cols-2 md:grid-cols-5 gap-2 place-items-center" id="massage-info">
		<label v-for="massage in listMassage" :for="'massage-' + useSlugify(massage.title)"
			class="btn btn-outline btn-primary btn-sm btn-block">
			{{ massage.title }}
		</label>
	</div>
</template>

<script setup lang="ts">
import { type MassageList } from '~/composables/useMassageList'

const listMassage: MassageList[] = useMassageList()
</script>
